/**
 * Internal event tracking libraries for directory page
 */
import analytics from 'analytics.js';

// [TODO] make tracking on/off configurable

export async function trackPractitionerList(source, practitionerIds) {
    const rootStore = source?.rootStore;

    const data = {
        practitionerIds,
        specialty_id: source?.specialty?.id,
        specialty: source?.specialty?.name,
        localityId: source?.locality?.id,
    };

    if (rootStore?.healthFundStore?.healthFund) {
        data['hsHealthFundId'] = rootStore.healthFundStore.healthFund?.id;
    }

    if (source?.bannerAds) {
        source.bannerAds.forEach((ad, index) => {
            data[`banner${index ? index + 1 : ''}Id`] = ad.id;
        });
    }

    return await analytics.track('practitionerList', data);
}

export async function trackBannerDetail(bannerAdId) {
    return analytics.track('bannerDetail', {
        'useContent': false,
        'bannerAdId': bannerAdId,
    });
}
