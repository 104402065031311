import {
    configure,
    observable,
    reaction,
    makeObservable,
    runInAction,
    computed,
} from 'mobx';
import React from 'react';
import ReactDOM from 'react-dom';
import http from 'http.js';
import Modal from 'core/components/Modal.js';
import ContactModal from 'core/components/ContactModal.js';
import Store from 'core/stores/Store.js';
import autobind from 'common/decorators/autobind.js';
import {toggleLoader} from 'core/utils.js';
import analytics from 'analytics.js';

configure({enforceActions: 'observed'});

export default class HospitalDirectoryStore extends Store {
    hasLoaded = false;
    locality = {};
    hospitalResult = [];
    initialHospitalResults = [];
    hasHospitalResultLoaded = false;
    isHealthFundChecked = false;
    isHFRDropdownOpen = false;
    searchResultsError = false;
    userType = null;
    filterDirectoryParams = [];

    constructor(rootStore) {
        super();
        this.rootStore = rootStore;
        this._currentRequest = null;
        makeObservable(this, {
            hasLoaded: observable,
            hasHospitalResultLoaded: observable,
            locality: observable,
            filterDirectoryParams: observable,
            hospitalResult: observable,
            isHealthFundChecked: observable,
            isHFRDropdownOpen: observable,
            searchResultsError: observable,
            initialHospitalResults: observable,
            userType: observable,

            headingString: computed,
            searchDirectoryParams: computed,
            selectedHFRAmount: computed,
            healthFundDisclaimer: computed,
            healthFundFilterText: computed,
            filteredHospitalRelationships: computed,
            validHospitalRelationships: computed,
            internalTrackingInfo: computed,
        });
        reaction(
            () =>
                !this.rootStore.healthFundStore.healthFundsLoading &&
                this.searchDirectoryParams,
            () => this.fetchHospitalResult(),
        );

        reaction(
            () => this.hasHospitalResultLoaded,
            (loaded) => {
                toggleLoader(loaded);
            },
        );

        reaction(
            () => this.rootStore.healthFundStore?.healthFund?.id,
            () => this.resetInitialResults(),
        );
        reaction(
            () => this.rootStore.healthFundStore?.healthFund,
            (healthFund) => {
                if (healthFund?.id) {
                    this.rootStore.healthFundStore?.fetchRelationships(
                        healthFund.id,
                    );
                }
            },
        );

        reaction(
            () => this.hospitalResult && this.hasHospitalResultLoaded,
            () => {
                if (this.hasHospitalResultLoaded) {
                    this.trackHospitalListEvent();
                }
            },
        );
    }

    get internalTrackingInfo() {
        const {
            clientLocalTime,
            deviceInfo: {device, screenSize, userAgent},
            userType,
        } = this.rootStore;

        const data = {
            'browser_screen_size': screenSize,
            'browser_useragent': userAgent,
            'created_local_time': clientLocalTime,
            'device': device,
            'user_type': userType,
        };
        return data;
    }

    get headingString() {
        const {name, postCode, state} = this.locality;
        return `Hospitals in ${name} ${state?.name} ${postCode}`;
    }

    get healthFundDisclaimer() {
        const hfFilterText = this.rootStore.healthFundStore?.healthFund
            ?.inNetworkHospitalInformation;
        return hfFilterText ? hfFilterText : '';
    }

    get healthFundFilterText() {
        let amount = '';
        let checked = this.rootStore.healthFundStore?.healthFundRelationships?.filter(
            (hf) => hf.checked === true,
        );
        if (checked && checked[0]?.id !== null) {
            amount = ` (${this.selectedHFRAmount})`;
        }
        return `Filter by network type${amount}`;
    }

    get selectedHFRAmount() {
        let amount = 1;
        amount = this.rootStore.healthFundStore?.healthFundRelationships?.filter(
            (hf) => hf.checked === true,
        ).length;
        return amount;
    }

    get validHospitalRelationships() {
        // returns all health fund relationships that are represented in hospital results
        const validHFRs = [];
        this.initialHospitalResults?.forEach((hospital) => {
            const relationship = hospital['health_fund_relationship']?.name;
            if (relationship && !validHFRs.includes(relationship)) {
                validHFRs.push(relationship);
            }
        });
        return validHFRs;
    }

    get filteredHospitalRelationships() {
        const filteredHFR = this.rootStore.healthFundStore?.healthFundRelationships?.filter(
            (hf) =>
                this.validHospitalRelationships.includes(hf.name) ||
                hf.id === null,
        );
        return filteredHFR;
    }

    get searchDirectoryParams() {
        const data = {
            locality: this.locality.id,
        };
        const {healthFund} = this.rootStore.healthFundStore;
        if (healthFund?.id) {
            data.health_fund = healthFund.id;
        }
        if (this.filterDirectoryParams.length > 0) {
            data.health_fund_relationship = this.filterDirectoryParams.map(
                (hfr) => hfr.hfrId,
            );
        }
        return data;
    }

    @autobind
    async trackHospitalListEvent() {
        const trackData = this.internalTrackingInfo;
        const {id} = this.locality;
        const hospitals = this.hospitalResult.results?.map((item) => item.id);
        const hfrs = this.filterDirectoryParams.map((hfr) => hfr.hfrName);

        if (hfrs.length > 0) {
            trackData['filters'] = hfrs;
        }

        trackData['locality'] = id;
        trackData['hospitals'] = hospitals;
        trackData[
            'health_fund'
        ] = this.rootStore.healthFundStore?.healthFund?.id;
        analytics.track('hospitalListEvent', trackData);
    }

    @autobind
    resetInitialResults() {
        this.updateStore({
            filterDirectoryParams: [],
            initialHospitalResults: [],
        });
    }

    @autobind
    async fetchHospitalResult() {
        if (!this.locality?.id) {
            return;
        }
        this.updateStore({
            hasHospitalResultLoaded: false,
        });
        const url = '/api/hospitals/v1/search/location/';
        let result = null;
        const request = http.get({url, data: this.searchDirectoryParams});
        if (this._currentRequest) {
            this._currentRequest.abort();
        }
        this._currentRequest = request;
        try {
            result = await this._currentRequest;
        } catch (error) {
            this.rethrowError(
                `Fetching hospital directory results URL failed with :${error}`,
            );
        } finally {
            if (result) {
                this.updateStore({
                    hospitalResult: result,
                    hasHospitalResultLoaded: true,
                });
                if (this.initialHospitalResults.length === 0) {
                    this.updateStore({
                        initialHospitalResults: result.results,
                    });
                }
            }
        }
    }

    @autobind
    updateFilterParams() {
        // Map all selected HFR and returns its Id
        const selectedHFs = this.rootStore.healthFundStore?.healthFundRelationships
            ?.filter((it) => it.checked === true && it.id !== null)
            .map((hf) => {
                return {hfrId: hf.id, hfrName: hf.name};
            });

        this.updateStore({
            filterDirectoryParams: [...selectedHFs],
            isHFRDropdownOpen: !this.isHFRDropdownOpen,
        });
    }

    @autobind
    hfrDropdownHandler(openStatus) {
        this.updateStore({
            isHFRDropdownOpen: openStatus,
        });
    }

    @autobind
    filterByHealthFundRelationship(hfrIdToUpdate) {
        const currentHFRs = Array.from(
            this.rootStore.healthFundStore.healthFundRelationships,
        );
        let updatedHealthFundRelationships = [];
        if (hfrIdToUpdate === null) {
            updatedHealthFundRelationships = currentHFRs.map((currentHFR) => {
                if (currentHFR.id !== null) {
                    runInAction(() => {
                        currentHFR.checked = false;
                    });
                }
                return currentHFR;
            });
        } else {
            updatedHealthFundRelationships = currentHFRs.map((currentHFR) => {
                if (currentHFR.id === hfrIdToUpdate) {
                    runInAction(() => {
                        currentHFR.checked = !currentHFR.checked;
                    });
                }
                if (currentHFR.id === null) {
                    runInAction(() => {
                        currentHFR.checked = false;
                    });
                }
                return currentHFR;
            });
        }
        const isNothingSelected = updatedHealthFundRelationships.every(
            (hfr) => hfr.checked === false,
        );
        if (isNothingSelected) {
            runInAction(
                () => (updatedHealthFundRelationships[0].checked = true),
            );
        }
        this.rootStore.healthFundStore.updateStore({
            healthFundRelationships: updatedHealthFundRelationships,
        });
    }

    @autobind
    async loadMore() {
        // TODO...might need pagination in the future
    }

    @autobind
    renderModal(props) {
        props.analytics = this.rootStore?.analyticsStore;
        return ReactDOM.createPortal(
            <Modal
                closeModal={props.closeModal}
                initElement={document.activeElement}
                parentElement={document.activeElement.parentElement}
            >
                <ContactModal {...props} isHospitalDirectory={true} />
            </Modal>,
            document.querySelector('#hospitalDirectory'),
        );
    }

    rethrowError(errorStr) {
        this.updateStore({searchResultsError: true});
        throw new Error(errorStr);
    }
}
